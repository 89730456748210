import React from "react"
import { compose, head, prop } from "ramda"
import {
  getEdgesForQuery,
  getImageQueryFluidData,
  getQueryData,
} from "@utils/utils"
import { useStaticQuery, graphql } from "gatsby"

import ImageGallery from "@components/shared/ImageGallery/ImageGallery"

const LeadershipGallery = () => {
  const data = useStaticQuery(graphql`
    query partnersGallery {
      photos: allImageSharp(
        filter: { fluid: { originalName: { glob: "partner*" } } }
        sort: { fields: resize___originalName }
      ) {
        edges {
          node {
            fluid(maxWidth: 600) {
              ...GatsbyImageSharpFluid_tracedSVG
              presentationHeight
              presentationWidth
              originalName
            }
          }
        }
      }
      metadata: allMarkdownRemark(
        filter: {
          frontmatter: {
            path: { eq: "/about" }
            section: { eq: "about-photos-owners" }
            published: { eq: true }
            lang: { eq: "de" }
          }
        }
      ) {
        edges {
          node {
            frontmatter {
              section
              owners {
                originalName
                label
                excerpt
              }
            }
          }
        }
      }
    }
  `)

  const metadata = compose(getQueryData, getEdgesForQuery("metadata"))(data)
  const photosMetadata = compose(prop("owners"), head)(metadata)

  const imageList = compose(
    getImageQueryFluidData,
    getEdgesForQuery("photos")
  )(data)

  const columns = {
    queries: ["(max-width: 767px)", "(min-width: 768px)"],
    values: [1, 2],
    defaultValue: 2,
  }

  return (
    <ImageGallery
      showLabelInfo
      metadata={photosMetadata}
      list={imageList}
      {...columns}
    />
  )
}

export default LeadershipGallery
