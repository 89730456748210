import React from "react"
import { graphql } from "gatsby"
import { compose, prop } from "ramda"
import { getEdgesForQuery, getQueryData, getSectionDataFor } from "@utils/utils"

/**
 * import Sections
 */
import MainLayout from "@layouts/MainLayout/MainLayout"
import SEO from "@components/shared/SEO/SEO"
import HeaderSection from "@components/shared/HeaderSection/HeaderSection"
import CopySection from "@components/shared/CopySection/CopySection"
import ContactSection from "@components/shared/ContactSection/ContactSection"
import NumbersSection from "@components/about/NumbersSection/NumbersSection"
import LeadershipGallery from "@components/about/LeadershipGallery/LeadershipGallery"
import QuoteSection from "@components/about/QuoteSection/QuoteSection"
import CustomerSection from "@components/about/CustomerSection/CustomerSection"

import imageAbout from "@assets/pictures/buehne_ueber-uns.svg"
import imageCustomers from "@assets/pictures/content_energieexperten.svg"

/**
 * quering data
 */
export const query = graphql`
  query AboutSectionIndexQuery {
    content: allMarkdownRemark(
      filter: {
        frontmatter: {
          path: { eq: "/about" }
          published: { eq: true }
          lang: { eq: "de" }
        }
      }
    ) {
      edges {
        node {
          frontmatter {
            path
            subtitle
            title
            section
            quotes {
              name
              role
              company
              quote
            }
          }
          internal {
            content
          }
        }
      }
    }
    # For future reference to whomever updates
    # customers: allImageSharp(
    #   filter: { fluid: { originalName: { glob: "content_customer_*"} } }
    #   sort: { fields: resize___originalName }
    # ) {
    # edges {
    #     node {
    #       fluid(maxWidth: 300) {
    #         ...GatsbyImageSharpFluid_tracedSVG
    #       }
    #     }
    #   }
    # }
    footer: allMarkdownRemark(
      filter: {
        frontmatter: {
          path: { eq: "/ui" }
          published: { eq: true }
          lang: { eq: "de" }
        }
      }
    ) {
      edges {
        node {
          frontmatter {
            section
            title
            subtitle
            cta
            ctaUrl
          }
          internal {
            content
          }
          html
        }
      }
    }
  }
`

const AboutPage = ({ data }) => {
  const content = compose(getQueryData, getEdgesForQuery("content"))(data)

  const headerSectionData = getSectionDataFor("about-section-header", content)
  const overviewSectionData = getSectionDataFor(
    "about-section-overview",
    content
  )
  const ownersSectionData = getSectionDataFor("about-section-owners", content)

  const quotesSectionData = getSectionDataFor("about-section-quotes", content)
  const quoteList = prop("quotes", quotesSectionData)

  const customersSectionData = getSectionDataFor(
    "about-section-customers",
    content
  )

  // For future reference to whomever updates
  // const customersGalleryList = compose(
  //   getImageQueryFluidData,
  //   getEdgesForQuery("customers")
  // )(data)

  const footer = compose(getQueryData, getEdgesForQuery("footer"))(data)
  const contactSectionData = getSectionDataFor(
    "ui-section-footer-contact",
    footer
  )

  return (
    <MainLayout>
      <SEO />
      <HeaderSection data={headerSectionData} image={imageAbout} />
      <CopySection
        className="bg-blue-lighter"
        anchor={"overview"}
        data={overviewSectionData}
      />
      <NumbersSection />
      <CopySection
        anchor={"leadership"}
        data={ownersSectionData}
        dotColor="red"
      >
        <>
          <LeadershipGallery />
        </>
      </CopySection>
      <QuoteSection data={quoteList} />
      <CustomerSection data={customersSectionData} image={imageCustomers} />
      <ContactSection data={contactSectionData} />
    </MainLayout>
  )
}

export default AboutPage
