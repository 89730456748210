import "./QuoteSection.scss"
import React, { useState } from "react"
import { map, prop, addIndex } from "ramda"
import { useSwipeable } from "react-swipeable"
import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  Col,
  Container,
  Row,
} from "reactstrap"

import zitat from "@assets/icons/ic_zitat.svg"
import arrow_left from "@assets/icons/ic_arrow-left.svg"
import arrow_right from "@assets/icons/ic_arrow-right.svg"

export default ({ data }) => {
  const [activeIndex, setActiveIndex] = useState(0)
  const [animating, setAnimating] = useState(false)

  const next = () => {
    if (animating) return
    const nextIndex = activeIndex === data.length - 1 ? 0 : activeIndex + 1
    setActiveIndex(nextIndex)
  }

  const previous = () => {
    if (animating) return
    const nextIndex = activeIndex === 0 ? data.length - 1 : activeIndex - 1
    setActiveIndex(nextIndex)
  }

  const goToIndex = newIndex => {
    if (animating) return
    setActiveIndex(newIndex)
  }

  const swipeHandlers = useSwipeable({
    onSwipedLeft: next,
    onSwipedRight: previous,
    delta: 10,
    preventDefaultTouchmoveEvent: true,
  })

  const slides = addIndex(map)((quote, index) => {
    const company = prop("company", quote)
    const name = prop("name", quote)
    const role = prop("role", quote)
    const text = prop("quote", quote)

    return (
      <CarouselItem
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
        key={index + 1}
      >
        <div className="carousel-content quotes-carousel-content-grid">
          <div className="quotes-carousel-content-flex-item-1">
            <img alt="Zitat" src={zitat} className="zitat-img" />
          </div>
          <div className="quotes-carousel-content-flex-item-2">
            <div className="carousel-content-text">
              <p className="display-4 font-weight-bold text-white">{text}</p>
              <p className="text-yellow-lighter">
                {name}, {role} {company}
              </p>
            </div>
          </div>
        </div>
      </CarouselItem>
    )
  }, data)

  return (
    <section
      id="quotes"
      className="py-4 py-sm-5 bg-blue-dark"
      {...swipeHandlers}
    >
      <Container>
        <Row className="align-items-center text-center py-3 py-sm-4 py-md-5">
          <Col>
            <Carousel
              interval={false}
              slide={false}
              enableTouch={true}
              activeIndex={activeIndex}
              next={next}
              previous={previous}
              className="carousel-fade"
            >
              {slides}
              <CarouselControl
                prevIcon={arrow_left}
                direction="prev"
                directionText="Previous"
                onClickHandler={previous}
              />
              <CarouselControl
                nextIcon={arrow_right}
                direction="next"
                directionText="Next"
                onClickHandler={next}
              />
            </Carousel>
            <CarouselIndicators
              items={data}
              activeIndex={activeIndex}
              onClickHandler={goToIndex}
            />
          </Col>
        </Row>
      </Container>
    </section>
  )
}
