import "./NumbersSection.scss"

import React from "react"
import { Container } from "reactstrap"
import CountUp from "react-countup"
import * as dayjs from "dayjs"

export default () => {
  return (
    <section id="" className="py-4 bg-blue-bright">
      <Container className="py-5 py-md-4">
        <div className="numbers-section-grid numbers-section-grid-item">
          <div className="numbers-section-grid-item-1">
            <div className="number-value text-red font-weight-bold">
              <CountUp
                start={dayjs(new Date()).year()}
                end={1997}
                delay={0}
                duration={10}
              />
            </div>
            <div className="number-label">Gründung</div>
          </div>
          <div className="numbers-section-grid-item-2 py-5 py-sm-0">
            <div className="number-value text-red font-weight-bold">
              <CountUp start={1} end={70} delay={0} duration={10} />
            </div>
            <div className="number-label">Mitarbeiter</div>
          </div>
          <div className="numbers-section-grid-item-3">
            <div className="number-value text-red font-weight-bold">
              <CountUp end={100} delay={0} duration={4} />%
            </div>
            <div className="number-label">Leidenschaft</div>
          </div>
        </div>
      </Container>
    </section>
    // <section className="numbers-section-wrapper d-flex">

    // </section>
  )
}
