import React from "react"
import GridSection from "@components/shared/GridSection/GridSection"

/**
 * import pictures
 */

export default ({ data: { title, content }, image }) => {
  return (
    <GridSection anchor={"customers"} className="switch-on-collapse">
      <div className="section-grid-copy">
        <h3 className="display-3 mb-0 font-weight-bolder dot dot-yellow">
          {title}
        </h3>
        <p className="mt-3 mb-5">{content}</p>
      </div>
      <div className="section-grid-media">
        <img alt={title} src={image}></img>
      </div>
    </GridSection>
  )
}
